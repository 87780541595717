<template>
    <div class="row no-gutters wizard-panel align-items-stretch">
        <div class="col-8">
            <panel full class="h-100">
                <panel-top-bar variant="primary-dark" with-logo />
                <div class="bg-primary p-4 text-white">
                    <div class="d-flex align-items-center justify-content-between mb-2">
                        <div>Progress</div>
                        <div>10%</div>
                    </div>
                    <b-progress max="100" variant="success" height="10px">
                        <b-progress-bar value="10" class="rounded"></b-progress-bar>
                    </b-progress>
                </div>
                <div class="panel-content">
                    <h1 class="text-primary-dark">Vehicle Type</h1>
                    <h6 class="text-primary-dark">Select multiple vehicle types which may be best suited for you courier requirements </h6>
                    <div class="row mt-5 px-5 align-items-center mx-0">
                        <div class="col-12 col-lg-auto" v-for="(vehicle, index) in vehicles" :key="index">
                            <vehicle-select
                                :title="vehicle.title"
                                :image-url="vehicle.imageUrl"
                                class="mb-3 mx-auto"
                            />
                        </div>
                    </div>
                </div>
                <div class="panel-footer panel-footer-box">
                    <button type="button" class="btn btn-default btn-lg text-primary-dark" @click="onBack">Back</button>
                    <button type="button" class="btn btn-primary btn-lg" @click="onBack">Next Step</button>
                </div>
            </panel>
        </div>
    </div>
</template>

<script>
    import Panel from "../../components/Panel";
    import PanelTopBar from "../../components/PanelTopBar";
    import VehicleSelect from "../../components/VehicleSelect";

    export default {
        name: 'VehicleSelection',
        components: {
            VehicleSelect,
            PanelTopBar,
            Panel
        },
        data() {
            return {
                vehicles: [
                    {title: 'Bicycle / Motocycle', imageUrl: '/assets/images/vehicles-demo/bicycle.jpg'},
                    {title: 'Car', imageUrl: '/assets/images/vehicles-demo/car.jpg'},
                    {title: 'SUV', imageUrl: '/assets/images/vehicles-demo/suv.jpg'},
                    {title: 'Full Sized Cargo Van', imageUrl: '/assets/images/vehicles-demo/full-sized-cargo.jpg'},
                    {title: 'Full Sized Pick-up Truck', imageUrl: '/assets/images/vehicles-demo/pick-up.jpg'},
                    {title: 'Box Truck', imageUrl: '/assets/images/vehicles-demo/box-truck.jpg'},
                    {title: 'Temperature Controlled Vehicle', imageUrl: '/assets/images/vehicles-demo/tcv.jpg'},
                    {title: 'Semi- Truck', imageUrl: '/assets/images/vehicles-demo/semi-truck.jpg'},
                ]
            }
        },
        methods: {
            onBack() {
                this.$router.push({ name: 'dashboard' });
            }
        }
    }
</script>
